<template>
  <nav
    class="znavbar"
    :class="[expandClass]"
  >
    <slot />
  </nav>
</template>

<script>
export default {
  props: {
    toggleable: {
      type: [String, Boolean],
      default: false,
    },
  },

  computed: {
    expandClass() {
      if (this.toggleable === true) {
        return undefined
      }

      return this.toggleable ? `znavbar-expand-${this.toggleable}` : 'znavbar-expand'
    },
  },
}
</script>
