<template>
  <component
    :is="tag"
    :to="to"
    class="znavbar-brand"
  >
    <slot />
  </component>
</template>

<script>
import { ZLink } from '#components'

export default {
  props: {
    to: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    tag() {
      if (this.to) {
        return ZLink
      }
      else {
        return 'div'
      }
    },
  },
}
</script>
