<template>
  <div>
    <div
      v-if="!isLoggedIn"
      class="auth"
    >
      <ZButton
        block
        data-testid="login"
        @click.prevent="closeNavAnd(showLogin)"
      >
        {{
          t('auth.logIn')
        }}
      </ZButton>
      <p class="signup">
        {{ t('auth.noAccount') }}
        <a
          href="#"
          data-testid="sign-up"
          @click.prevent="closeNavAnd(showSignup)"
        >{{
          t('auth.signUp')
        }}</a>
      </p>
    </div>

    <ZListGroup flush>
      <template v-if="isLoggedIn">
        <ZListGroupItem
          action
          class="dropdown"
          data-testid="my-rvezy"
          @click="subNavCollapse = !subNavCollapse"
        >
          <span class="icon">
            <IconFavicon />
            <span
              v-if="dashboardBadge"
              class="push-note"
            />
          </span>
          {{ t('pageLink.myRvezy') }}

          <fa
            icon="chevron-down"
            :class="{ rotated: subNavCollapse }"
          />

          <ZCollapse
            full-width
            :visible="subNavCollapse"
          >
            <AppDashboardNavbar
              :show-dashboard-badge="dashboardBadge"
              @nav-collapse="hideCollapse"
            />
          </ZCollapse>
        </ZListGroupItem>

        <ZListGroupItem
          :to="localePath('/dashboard/favourites')"
          @click="closeNavAndGoTo(localePath('/dashboard/favourites'))"
        >
          <span class="icon">
            <fa :icon="['fal', 'heart']" />
          </span>
          {{ t('pageLink.favourites') }}
          <fa icon="chevron-right" />
        </ZListGroupItem>
        <ZListGroupItem
          class="inbox"
          :to="localePath('/dashboard/messages')"
          @click="closeNavAndGoTo(localePath('/dashboard/messages'))"
        >
          <span class="icon">
            <fa :icon="['fal', 'comment']" />
            <span
              v-if="inboxBadge"
              class="push-note"
            />
          </span>

          <span class="label">
            {{ t('pageLink.inbox') }}
            <span
              v-if="inboxBadge"
              class="inbox-badge"
            >{{ inboxBadge }}</span>
          </span>
          <fa icon="chevron-right" />
        </ZListGroupItem>
      </template>

      <ZListGroupItem
        v-if="!isLoggedIn"
        data-testid="list-an-rv"
        :to="linkListRv"
        class="list-an-rv"
        @click="closeNavAndGoTo(linkListRv)"
      >
        <span class="icon">
          <fa :icon="['fal', 'rv']" />
        </span>
        {{ t('listAnRV') }}
        <fa icon="chevron-right" />
      </ZListGroupItem>

      <ZListGroupItem
        :href="t('helpCenter.url')"
        @click="handleTrackHelpCenterClicked"
      >
        <span class="icon">
          <fa :icon="['fal', 'message-question']" />
        </span>
        {{ t('helpCenter.title') }}
        <fa icon="chevron-right" />
      </ZListGroupItem>

      <ZListGroupItem @click="downloadApp">
        <span class="icon">
          <IconFavicon />
        </span>
        {{ t('downloadApp') }}
        <fa icon="chevron-right" />
      </ZListGroupItem>

      <ZListGroupItem
        v-if="isLoggedIn"
        data-testid="list-an-rv"
        :to="linkListRv"
        class="list-an-rv"
        @click="closeNavAndGoTo(linkListRv)"
      >
        <span class="icon">
          <fa :icon="['fal', 'rv']" />
        </span>
        {{ t('listAnRV') }}
        <fa icon="chevron-right" />
      </ZListGroupItem>

      <ZListGroupItem>
        <span class="icon">
          <fa :icon="['fal', 'shop']" />
        </span>
        <ButtonBuyAnRv
          :text-btn="t('buyAnRv')"
          cta="mobile-menu"
          is-link
        />
        <fa icon="chevron-right" />
      </ZListGroupItem>

      <AppLocalization class="localization-mobile" />
    </ZListGroup>
    <div class="bottom-button-container">
      <ZLink
        v-if="isLoggedIn"
        href="#"
        class="logout"
        data-testid="profile-logout"
        @click.prevent="logout"
      >
        {{ t('actions.logOut') }}
      </ZLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { trackHelpCenterClicked } from '~/lib/tracking'

defineProps({
  inboxBadge: {
    type: Number,
    default: 0,
  },

  dashboardBadge: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['closeNav'])

const { isLoggedIn, logOut } = useAuthentication()
const { routeBaseName } = useBaseName()
const { showLogin } = useLogin()
const { showSignup } = useSignup()
const { linkListRv } = useOwnerCommon()
const { downloadApp } = useDownloadApp()
const { t } = useI18n()
const localePath = useLocalePath()

const subNavCollapse = ref(false)

function closeNavAnd(callbackFn: () => void) {
  hideCollapse(() => callbackFn && callbackFn())
}

function closeNavAndGoTo(path: string) {
  hideCollapse(async () => {
    await navigateTo(path)
  })
}

function hideCollapse(callback: () => void) {
  emit('closeNav')

  if (callback && callback instanceof Function) {
    callback()
  }
}

function handleTrackHelpCenterClicked() {
  trackHelpCenterClicked({
    pageSource: routeBaseName.value,
  })
}

function logout() {
  hideCollapse(async () => await logOut())
}
</script>

<style lang="scss" scoped>
.auth {
  padding: 1rem 0;

  .signup {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.875rem;

    a {
      text-decoration: underline;
    }
  }
}

.bottom-button-container {
  padding: 1rem 1.25rem;

  .logout {
    @include caption-strong;
    font-size: 1rem;
    color: getColor('primary-500');
    text-decoration: underline;
  }
}

.zmedia {
  margin: 2rem 0 1rem;

  h5 {
    margin-bottom: 0;
  }
}

:deep(.zlist-group-item) {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  color: getColor('primary-500');
  border-color: getColor('primary-100');
  padding: 1rem 1.25rem;

  svg[class*='fa-chevron-right'] {
    margin-left: auto;
  }

  .fa-chevron-down {
    transition: transform 0.2s ease-in-out;

    &.rotated {
      transform: rotate(180deg);
    }
  }

  .zlink-md {
    font-weight: normal;
  }

  &.zlist-group-item-action {
    cursor: pointer;
    color: getColor('primary-500');

    &.logout {
      color: getColor('danger-500');
    }
  }

  &:hover {
    background-color: transparent;
  }

  &.dropdown {
    flex-wrap: wrap;

    svg[class*='fa-chevron'] {
      margin: 0 0 0 auto;
    }
  }

  .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 1rem;
    height: 1rem;

    svg {
      width: 1rem;
      height: 1rem;
    }

    .push-note {
      width: 0.5rem;
      height: 0.5rem;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }
  }

  small {
    // TODO: find proper text-style

    font-size: 0.875rem;
    color: getColor('primary-400');
    display: block;
  }
}

:deep(.dashboard-navbar) {
  margin: 1rem 1.25rem 1rem 0.5rem;
  padding-left: 1rem;
  border-left: 1px solid getColor('primary-100');

  li + li {
    margin-top: 0.75rem;
  }

  a {
    padding: 0.25rem 0 !important;
    color: getColor('primary-350') !important;
    font-weight: normal !important;
    font-size: 1rem !important;
  }
}

// The extra specificity is needed to override the default styles of the component.
// As the CSS output has a different order in production builds vs local builds :(
div.localization-mobile {
  width: 100%;
  gap: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  margin-top: 0.5rem;

  :deep(button.zbtn) {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    color: getColor('primary-500');
    border-color: getColor('primary-100');
    font-weight: normal;
    font-size: 1rem;
    padding: 0.5rem 1.25rem;

    border: 0;
    background-color: transparent;

    &.toggle-currency {
      border-left: 1px solid getColor('primary-100');

      > span {
        color: getColor('highlight-500');
      }
    }
  }
}

.label {
  display: inline-flex;
  align-items: center;
}

.inbox-badge {
  position: relative;
  transform: translateY(1px);

  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: 1.125rem;
  height: 1.125rem;
  padding: 0 0.35rem;
  border-radius: 1rem;

  background: getColor('highlight-500');
  font-size: 0.75rem;
  color: white;

  margin-left: 1rem;
}
</style>

<i18n src="~/locales/common/app.json" lang="json" />

<i18n src="~/locales/common/auth.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "downloadApp": "Download the app"
  },
  "fr": {
    "downloadApp": "Télécharger l’app"
  }
}
</i18n>
